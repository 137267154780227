import React, { useContext } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../index";
import { ErrorContext } from "../../errors/ErrorContext";

export const ErrorDialog: React.FC = () => {
  const { open, onOpenChange, error } = useContext(ErrorContext);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle style={{ color: "hsl(var(--destructive))" }}>
            {error.title}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>{error.message}</DialogDescription>
        <DialogFooter>
          <Button
            variant={
              (error.acceptVariant as "primary" | "secondary") ?? "primary"
            }
            onClick={() => onOpenChange(false)}
          >
            {error.acceptLabel ?? "Accept"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
