import { cloneElement, forwardRef, TextareaHTMLAttributes } from "react";

import { twMerge } from "tailwind-merge";

import { cn } from "../../../utils";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  error?: string;
  helperText?: string;
  containerClassName?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      startIcon,
      endIcon,
      error,
      helperText,
      containerClassName,
      ...props
    },
    ref
  ) => (
    <div className={twMerge("flex flex-col gap-1", containerClassName)}>
      <div
        className={twMerge(
          "flex gap-2 items-center w-full rounded-md bg-background py-2 pl-2 pr-4 border text-sm",
          className
        )}
      >
        {startIcon && (
          <div className="flex items-center justify-center">
            {cloneElement(startIcon as React.ReactElement, {
              className: "h-5 w-5",
            })}
          </div>
        )}
        <textarea
          className={cn(
            "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 w-full",
            error ? "border-red-500" : "border-input"
          )}
          ref={ref}
          name={props.name ?? props.id}
          {...props}
        />
        {endIcon && (
          <div className="flex items-center justify-center">
            {cloneElement(endIcon as React.ReactElement, {
              className: "h-5 w-5",
            })}
          </div>
        )}
      </div>
      {error && <div className="text-xs text-red-500 font-medium">{error}</div>}
      {helperText && (
        <div className="text-xs text-muted-foreground">{helperText}</div>
      )}
    </div>
  )
);
