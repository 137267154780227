/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { Filter } from "./index";

export interface FilterableTableContextType {
  // Actions
  toggleFilterOption: (filter: Filter, option: FilterOption) => void;
  isFilterOptionSelected: (filter: Filter, option: FilterOption) => boolean;
  clearFilterValues: (filter: Filter) => void;
  sort: (sortOption: string) => void;
  filter: (filterValues: Record<string, string[]>) => void;

  // Values
  sortValues: string[];
  filterValues: Record<string, string[]>;
  data?: any[];
  columns?: ColumnDef<any>[];
}

export interface FilterOption {
  label: string;
  value: string;
  count?: number;
}

const FilterableTableContext = createContext<FilterableTableContextType>({
  // Actions
  toggleFilterOption: () => {},
  isFilterOptionSelected: () => false,
  clearFilterValues: () => {},
  sort: () => {},
  filter: () => {},

  // Values
  sortValues: [],
  filterValues: {},
});

export default FilterableTableContext;
