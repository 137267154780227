import { DocumentTag } from "@atlas-ui/types";

import API from "../api";

export interface GetDocumentTagsParams {
  documentId?: string;
  documentTypeId?: string | null;
  insights?: boolean;
  abortSignal?: AbortSignal;
}

export interface CreateDocumentTagParams {
  documentId: string;
  name: string;
  abortSignal?: AbortSignal;
}

export interface UpdateDocumentTagsParams {
  tags: (Partial<DocumentTag> & { id: string })[];
  abortSignal?: AbortSignal;
}

export const getDocumentTags = async ({
  documentId,
  documentTypeId,
  insights,
  abortSignal,
}: GetDocumentTagsParams) => {
  try {
    const { data } = await API.get(`/document-tags`, {
      params: { documentId, documentTypeId, insights },
      signal: abortSignal,
    });

    return data as DocumentTag[];
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting document tags");
  }
};

export const createDocumentTag = async ({
  documentId,
  name,
  abortSignal,
}: CreateDocumentTagParams) => {
  try {
    const { data } = await API.post(
      `/document-tags`,
      {
        documentId,
        name,
      },
      {
        signal: abortSignal,
      }
    );

    return data;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error creating document tag");
  }
};

export const updateDocumentTags = async ({
  tags,
  abortSignal,
}: UpdateDocumentTagsParams) => {
  try {
    return await Promise.all(
      tags.map(async (tag) => {
        const { data } = await API.patch(
          `/document-tags/${tag.id}`,
          {
            name: tag.name,
          },
          {
            signal: abortSignal,
          }
        );

        return data;
      })
    );
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error updating document tags"
    );
  }
};

export const removeDocumentTag = async (id: string) => {
  try {
    await API.delete(`/document-tags/${id}`);
  } catch (error) {
    throw new Error((error as Error)?.message || "Error removing document tag");
  }
};
