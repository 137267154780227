import { forwardRef, HTMLAttributes } from "react";

import { cn } from "../../../utils";

export const AlertDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    className={cn(
      "mt-1 dark:text-white text-black text-sm [&_p]:leading-relaxed leading-6",
      className
    )}
  >
    {props.children}
  </p>
));

AlertDescription.displayName = "AlertDescription";
