/* eslint-disable @typescript-eslint/no-explicit-any */
export enum StrategyTypes {
  DIRECT_LLM_QUERY = "DIRECT_LLM_QUERY",
  ATLAS_SIMPLE_QUERY = "ATLAS_SIMPLE_QUERY",
  SMALL_TO_BIG = "SMALL_TO_BIG",
}

export type StrategyHyperparams = { [key: string]: any };

export interface Strategy {
  id: string;
  name: string;
  type: StrategyTypes;
  description: string;
  hyperparameters: StrategyHyperparams;

  _original?: Strategy;
}
