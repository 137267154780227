import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { Cancel } from "@radix-ui/react-alert-dialog";

import { cn } from "../../../utils";
import { ButtonSize, ButtonVariant, buttonVariants } from "./Button";

interface AlertDialogCancelProps
  extends ComponentPropsWithoutRef<typeof Cancel> {
  variant?: ButtonVariant;
  size?: ButtonSize;
}

export const AlertDialogCancel = forwardRef<
  ElementRef<typeof Cancel>,
  AlertDialogCancelProps
>(({ className, variant, size, ...props }, ref) => (
  <Cancel
    ref={ref}
    className={cn(
      buttonVariants({
        variant: variant ?? "secondary",
        size: size ?? "md",
      }),
      "mt-2 sm:mt-0",
      className
    )}
    {...props}
  />
));

AlertDialogCancel.displayName = Cancel.displayName;
