import { FC } from "react";

import {
  RenderThumbnailItemProps,
  ThumbnailPlugin,
} from "@react-pdf-viewer/thumbnail";

import { cn } from "../../../utils";

export interface PdfViewerSidebarProps {
  plugins: {
    thumbnailPluginInstance: ThumbnailPlugin;
  };
}

export const PdfViewerSidebar: FC<PdfViewerSidebarProps> = ({ plugins }) => {
  const {
    thumbnailPluginInstance: { Thumbnails },
  } = plugins;

  const renderThumbnailItem = ({
    currentPage,
    pageIndex,
    renderPageThumbnail,
    onJumpToPage,
  }: RenderThumbnailItemProps) => (
    <button
      className={cn(
        currentPage === pageIndex
          ? "border-[3px] border-accent-foreground"
          : "bg-muted",
        "flex flex-col gap-2 items-center mb-4"
      )}
      onClick={onJumpToPage}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") {
          onJumpToPage();
        }
      }}
      key={pageIndex}
    >
      {renderPageThumbnail}
    </button>
  );

  return (
    <div className="flex px-2 max-h-full">
      <div className="flex-1 flex justify-center">
        <Thumbnails renderThumbnailItem={renderThumbnailItem} />
      </div>
    </div>
  );
};
