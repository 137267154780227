"use client";

import { ComponentPropsWithoutRef, ElementRef, FC, forwardRef } from "react";

import {
  AlertDialogPortalProps,
  Content,
  Overlay,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-alert-dialog";

import { cn } from "../../../utils";

const AlertDialog = Root;

const AlertDialogTrigger = Trigger;

const AlertDialogPortal: FC<AlertDialogPortalProps> = ({ ...props }) => (
  <Portal data-testid="alert-dialog-portal" {...props} />
);

const AlertDialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ className, children, ...props }, ref) => (
  <Overlay
    data-testid="alert-dialog-overlay"
    className={cn(
      "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
    ref={ref}
  />
));

AlertDialogOverlay.displayName = Overlay.displayName;

const AlertDialogContent = forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, ...props }, ref) => (
  <AlertDialogPortal>
    <AlertDialogOverlay />
    <Content
      data-testid="alert-dialog-content"
      ref={ref}
      className={cn(
        "fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
        className
      )}
      {...props}
    />
  </AlertDialogPortal>
));

AlertDialogContent.displayName = Content.displayName;

export { AlertDialog, AlertDialogTrigger, AlertDialogContent };
