import { forwardRef, HTMLAttributes } from "react";

import { cn } from "../../../utils";

export const CardTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  >
    {props.children}
  </h3>
));

CardTitle.displayName = "CardTitle";
