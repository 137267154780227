import { Strategy } from "@atlas-ui/types";

import API from "../api";

// Strategies endpoints
export const getStrategies = async (
  problemTypeId?: string
): Promise<Strategy[]> => {
  try {
    const response = await API.get<Strategy[]>("/admin/strategies", {
      params: {
        problemTypeId,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting strategies");
  }
};
