import React from "react";

import { StarIcon } from "@heroicons/react/24/solid";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const renderFavoriteIcon = (isFavorite: boolean) => {
  const props = {
    "data-testid": "favorite-icon",
    className: `h-5 w-5 ${isFavorite ? "text-primary" : "text-input"}`,
  };

  return <StarIcon {...props} />;
};
