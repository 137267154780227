import { FC } from "react";

import { truncate } from "lodash";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltips";

interface TruncatedTextProps {
  textLength?: number;
  children: string;
}

export const TruncatedText: FC<TruncatedTextProps> = ({
  children,
  textLength,
  ...props
}) => {
  if (children.length <= 20) return <span {...props}>{children}</span>;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span {...props}>
            {truncate(children, { length: textLength ?? 20 })}
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <span>{children}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
