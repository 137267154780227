import { cloneElement, FC, HTMLAttributes, ReactElement, useRef } from "react";

import { useHover } from "usehooks-ts";

import { cn } from "../../../utils";

interface KeyValuePairButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon: ReactElement;
  label: string;
  isActive?: boolean;
  onClick: () => void;
}

export const KeyValuePairButton: FC<KeyValuePairButtonProps> = ({
  icon,
  label,
  isActive,
  onClick,
  ...rest
}) => {
  const hoverRef = useRef<HTMLButtonElement>(null);
  const isHovering = useHover(hoverRef);

  return (
    <button
      className="flex items-center justify-center flex-col gap-1 border-none text-center"
      onClick={onClick}
      ref={hoverRef}
      {...rest}
    >
      <div className="flex items-center justify-center">
        {cloneElement(icon, {
          className: `h-4 w-4 ${
            isHovering || isActive ? "text-primary" : "text-muted-foreground"
          } transition-colors`,
          "data-testid": "icon",
        })}
      </div>
      <span
        className={cn(
          "text-xs font-medium transition-colors",
          isHovering || isActive ? "text-primary" : "text-muted-foreground"
        )}
        data-testid="label"
      >
        {label}
      </span>
    </button>
  );
};
