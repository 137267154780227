import { FC, HTMLAttributes } from "react";

import { StarIcon } from "@heroicons/react/24/solid";

import { capitalize } from "lodash";

import { Spinner } from "../spinners/Spinner";

interface KeyValuePairContainerProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  value: string;
  isFavorite?: boolean;
  isLoading?: boolean;
}

export const KeyValuePairContainer: FC<KeyValuePairContainerProps> = ({
  label,
  value,
  isFavorite,
  isLoading,
  ...rest
}) => (
  <div className="p-4 min-w-[100px] max-w-[390px]" {...rest}>
    <div className="flex">
      <label className="text-foreground font-medium line-clamp-3 break-words flex-1">
        {capitalize(label)}
      </label>
      <div className="flex items-start pt-[2px] ml-1">
        {isFavorite && (
          <StarIcon
            data-testid="favorite-icon"
            className="h-4 w-4 text-primary"
          />
        )}
      </div>
    </div>
    <div className="text-muted-foreground truncate font-normal" title={value}>
      {!isLoading &&
        (value === "N/A" ? (
          <span className="text-muted-foreground italic">N/A</span>
        ) : (
          value
        ))}
      {isLoading && <Spinner size={4} data-testid="loading-spinner" />}
    </div>
  </div>
);
