/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext, ReactNode, useMemo, useState } from "react";

import { Row } from "@tanstack/react-table";

interface RowContextType<T> {
  row?: Row<T>;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}

export const RowContext = createContext<RowContextType<any>>({
  row: undefined,
  isExpanded: false,
  setIsExpanded: () => {},
});

interface RowContextProviderProps<T> {
  row: Row<T>;
  children: ReactNode;
}

interface RowContextConsumerProps<T> {
  children: (value: RowContextType<T>) => ReactNode;
}

export const RowContextProvider = <T,>({
  row,
  children,
}: RowContextProviderProps<T>) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const value = useMemo(
    () => ({ row, isExpanded, setIsExpanded }),
    [row, isExpanded, setIsExpanded]
  );

  return <RowContext.Provider value={value}>{children}</RowContext.Provider>;
};

export const RowContextConsumer = <T,>({
  children,
}: RowContextConsumerProps<T>) => {
  return <RowContext.Consumer>{children}</RowContext.Consumer>;
};
