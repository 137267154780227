import { DocumentType, DocumentTypeWithCount } from "@atlas-ui/types";

import API from "../api";

interface GetDocumentTypesParams {
  withDocuments?: boolean;
  insightsEnabled?: boolean;
}

export const getDocumentTypes = async (
  { withDocuments, insightsEnabled }: GetDocumentTypesParams = {
    withDocuments: false,
    insightsEnabled: false,
  }
) => {
  try {
    const response = await API.get(`/document-types`, {
      params: {
        withDocuments,
        insightsEnabled,
      },
    });

    return response.data as DocumentType[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting document types"
    );
  }
};

export const getDocumentTypesWithCount = async () => {
  try {
    const response = await API.get(`/document-types/count`);

    return response.data as DocumentTypeWithCount[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting document types"
    );
  }
};
