import { FileWithExtension } from "@atlas-ui/services";

export type SupportedMimeType = keyof typeof FileFormats;

export enum FileFormats {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" = "docx",
  "application/pdf" = "pdf",
  "image/png" = "png",
  "image/jpeg" = "jpeg",
}

export const validMimeTypes: SupportedMimeType[] = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const getSupportedMimeTypes = (
  fileFormat: FileFormats
): SupportedMimeType[] => {
  return validMimeTypes.filter(
    (mimeType) => FileFormats[mimeType] === fileFormat
  );
};

export const addExtensionToFile = (file: File): FileWithExtension => {
  const ext = FileFormats[file.type as SupportedMimeType];

  return { file, ext };
};
