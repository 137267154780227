"use client";

import {
  ComponentPropsWithoutRef,
  ElementRef,
  FC,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from "react";

import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import {
  Content,
  Group,
  Icon,
  Item,
  ItemIndicator,
  ItemText,
  Label,
  Portal,
  Root,
  Separator,
  Trigger,
  Value,
  Viewport,
} from "@radix-ui/react-select";

import { cva, type VariantProps } from "class-variance-authority";
import { isEmptyChildren } from "formik";
import { Check } from "lucide-react";

import { cn } from "../../../utils";

const Select = Root;

const SelectGroup = Group;

const SelectValue = Value;

const selectTriggerVariants = cva(
  "flex gap-1 w-full items-center rounded-md border border-input bg-transparent px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      size: {
        default: "h-10 text-sm",
        sm: "h-8 text-xs",
        lg: "h-12 text-base",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
);

interface SelectTriggerProps
  extends ComponentPropsWithoutRef<typeof Trigger>,
    VariantProps<typeof selectTriggerVariants> {
  icon?: ReactNode;
  containerClassName?: string;
}

const SelectTrigger = forwardRef<
  ElementRef<typeof Trigger>,
  SelectTriggerProps
>(({ className, children, icon, size, containerClassName, ...props }, ref) => (
  <Trigger
    ref={ref}
    className={cn(selectTriggerVariants({ size, className }))}
    {...props}
  >
    {children}
    <div
      className={cn("flex-1 flex justify-end items-center", containerClassName)}
    >
      <Icon asChild>
        {icon || icon === null ? (
          icon
        ) : (
          <ChevronUpDownIcon className="h-4 w-4 fill-muted-foreground" />
        )}
      </Icon>
    </div>
  </Trigger>
));

SelectTrigger.displayName = Trigger.displayName;

const SelectPrefix: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={cn("text-muted-foreground text-xs font-medium", className)}
      {...props}
    >
      {children}
    </div>
  );
};

const selectContentVariants = cva(
  "relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
  {
    variants: {
      position: {
        popper:
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        "item-aligned": "",
      },
    },
    defaultVariants: {
      position: "popper",
    },
  }
);

interface SelectContentProps
  extends Omit<ComponentPropsWithoutRef<typeof Content>, "position">,
    VariantProps<typeof selectContentVariants> {}

const SelectContent = forwardRef<
  ElementRef<typeof Content>,
  SelectContentProps
>(({ className, children, position = "popper", ...props }, ref) => (
  <Portal>
    <Content
      ref={ref}
      className={cn(selectContentVariants({ position, className }))}
      position={position ?? "popper"}
      {...props}
    >
      <Viewport
        className={cn(
          "p-1",
          position === "popper" &&
            "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
        )}
      >
        {children}
        {(!children || isEmptyChildren(children)) && (
          <span className={"text-sm text-muted-foreground font-semibold px-8"}>
            No items
          </span>
        )}
      </Viewport>
    </Content>
  </Portal>
));

SelectContent.displayName = Content.displayName;

const SelectLabel = forwardRef<
  ElementRef<typeof Label>,
  ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => (
  <Label
    ref={ref}
    className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)}
    {...props}
  />
));

SelectLabel.displayName = Label.displayName;

const SelectItem = forwardRef<
  ElementRef<typeof Item>,
  ComponentPropsWithoutRef<typeof Item>
>(({ className, children, ...props }, ref) => (
  <Item
    ref={ref}
    className={cn(
      "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <ItemIndicator>
        <Check className="h-4 w-4" />
      </ItemIndicator>
    </span>

    <ItemText>{children}</ItemText>
  </Item>
));

SelectItem.displayName = Item.displayName;

const SelectSeparator = forwardRef<
  ElementRef<typeof Separator>,
  ComponentPropsWithoutRef<typeof Separator>
>(({ className, ...props }, ref) => (
  <Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-muted", className)}
    {...props}
  />
));

SelectSeparator.displayName = Separator.displayName;

export {
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectPrefix,
  Select,
};
