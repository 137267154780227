import { FC, PropsWithChildren, PropsWithRef } from "react";

export enum IndustryType {
  LegalServices = "Legal Services",
  RealEstate = "Real Estate",
  InformationTechnology = "Information Technology",
  Manufacturing = "Manufacturing",
  Retail = "Retail",
  Government = "Government",
  Sales = "Sales",
  Other = "Other",
}

export enum RoleType {
  Executive = "Executive",
  Management = "Management",
  Compliance = "Compliance",
  Procurement = "Procurement",
  Sales = "Sales",
  ParaLegal = "Para-Legal",
  Lawyer = "Lawyer",
  Other = "Other",
}

export interface PaginationParams {
  search?: string;
  skip?: number;
  take?: number;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
}

export interface InsightsParams extends PaginationParams {
  tags?: string[];
  parties?: string[];
}

export interface PaginatedResponse<T> {
  result: T[];
  total: number;
  skip: number;
  take: number;
}

export type PropsWithChildrenAndRef<T> = PropsWithChildren<PropsWithRef<T>>;
export type FCWithChildrenAndRef<T> = FC<PropsWithChildrenAndRef<T>>;
