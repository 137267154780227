import {
  forwardRef,
  HTMLAttributes,
  LegacyRef, ReactElement,
  ReactNode
} from "react";

import { cn } from "../../../utils";
import { Button, Separator } from "../../atoms";

export interface SectionWithHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | ReactElement;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  className?: string;
  children: ReactNode;
  containerClassName?: string;
  belowHeaderSlot?: ReactNode;
  headerSlot?: ReactNode;
  hideSeparators?: boolean;
}

export const SectionWithHeader = forwardRef<
  HTMLDivElement,
  SectionWithHeaderProps
>(
  (
    {
      title,
      buttonLabel,
      buttonOnClick,
      children,
      belowHeaderSlot,
      headerSlot,
      hideSeparators,
      containerClassName,
      ...rest
    },
    ref
  ) => {
    const renderHeaderSlot = () => {
      if (buttonLabel) {
        return (
          <Button
            variant="secondary"
            onClick={buttonOnClick}
            size="xl"
            className="text-sm font-normal"
          >
            {buttonLabel}
          </Button>
        );
      } else if (headerSlot) {
        return headerSlot;
      }

      return null;
    };

    return (
      <div
        className={cn("flex flex-col gap-4", containerClassName)}
        ref={ref as LegacyRef<HTMLDivElement>}
      >
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <span className="text-lg font-medium flex-1">{title}</span>
            {renderHeaderSlot()}
          </div>
        </div>
        {belowHeaderSlot && <div>{belowHeaderSlot}</div>}
        {!hideSeparators && (
          <div>
            <Separator orientation="horizontal" />
          </div>
        )}
        <div {...rest}>{children}</div>
        {!hideSeparators && (
          <div>
            <Separator orientation="horizontal" />
          </div>
        )}
      </div>
    );
  }
);

SectionWithHeader.displayName = "SectionWithHeader";
