import { Organization, OrganizationLimits, User } from "@atlas-ui/types";

import API from "../api";

export const getOrganizations = async () => {
  try {
    const { data } = await API.get("/organizations/me");

    return data as Organization[];
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting organizations");
  }
};

export const getOrganizationMembers = async (organizationId: string) => {
  try {
    const { data } = await API.get(`/organizations/${organizationId}/users`);

    return data as User[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting organization members"
    );
  }
};

export const getOrganizationLimits = async (
  organizationId?: string
): Promise<OrganizationLimits> => {
  if (!organizationId) {
    throw new Error("Organization ID is required");
  }

  try {
    const { data } = await API.get(`/organizations/${organizationId}/limits`);

    return data as OrganizationLimits;
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting organization limits"
    );
  }
};
