import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { getDocumentById, patchDocument } from "@atlas-ui/services";
import { Document } from "@atlas-ui/types";

import { isEqual, omit } from "lodash";

import { usePrevious } from "../usePrevious";

export interface UseDocumentProps {
  documentId: string;
  autoFetch?: boolean;
  onSuccess?: (data: Document) => void;
}

export interface UpdateOptions {
  doUpdateLocal?: boolean;
  updateOnlyLocal?: boolean;
}

interface MutationContext {
  previousDocument?: Document;
}

const getCachedDocument = (documentId: string): Document | undefined => {
  const cachedDocument = localStorage.getItem(`document-${documentId}`);
  return cachedDocument ? JSON.parse(cachedDocument) : undefined;
};

const updateLocalStorage = (documentId: string, data: Document): void => {
  localStorage.setItem(`document-${documentId}`, JSON.stringify(data));
};

export const useDocuments = ({
  documentId,
  onSuccess,
  autoFetch = true,
}: UseDocumentProps) => {
  const queryClient = useQueryClient();

  const previousDocumentId = usePrevious(documentId);
  const queryKey = useMemo(() => {
    return ["document", documentId];
  }, [!isEqual(previousDocumentId, documentId)]);

  const {
    data: document,
    isError,
    error: queryError,
    isLoading,
    refetch,
  } = useQuery<Document, Error>(
    queryKey,
    async () => {
      return await getDocumentById(documentId, { unmarkAsNew: true });
    },
    {
      enabled: autoFetch && !!documentId,
      initialData: () => getCachedDocument(documentId),
      onSuccess: (data) => {
        updateLocalStorage(documentId, data);

        // Updates the documents in progress list
        onSuccess?.(data);
      },
    }
  );

  const error = isError ? queryError?.message : undefined;

  const updateMutation = useMutation<
    Document,
    Error,
    Partial<Document>,
    MutationContext
  >(
    (updatedDocument) =>
      patchDocument({
        id: documentId,
        name: updatedDocument.name ?? document?.name ?? "",
        ...omit(updatedDocument, "kvps"),
      }),
    {
      onMutate: async (updatedDocument) => {
        await queryClient.cancelQueries(["document", documentId]);

        const previousDocument = queryClient.getQueryData<Document>([
          "document",
          documentId,
        ]);

        if (previousDocument) {
          const updatedDoc = updateKVPs(previousDocument, updatedDocument);
          queryClient.setQueryData(["document", documentId], {
            ...updatedDoc,
            ...omit(updatedDocument, "kvps"),
          });

          updateLocalStorage(documentId, updatedDoc);
        }

        return { previousDocument };
      },
      onError: (err, newDocument, context) => {
        if (context?.previousDocument) {
          queryClient.setQueryData(
            ["document", documentId],
            context.previousDocument
          );
        }
      },
      onSettled: () => {
        return queryClient.invalidateQueries(["document", documentId]);
      },
    }
  );

  const updateKVPs = (
    previousDocument: Document,
    updatedDocument: Partial<Document>
  ): Document => {
    const { kvps } = updatedDocument;
    let newKvps = previousDocument.kvps ?? [];
    const kvpsBeingAdded = kvps?.filter(
      (kvp) => !newKvps.some((newKvp) => newKvp.id === kvp.id)
    );
    const kvpsBeingUpdated = kvps?.filter((kvp) =>
      newKvps.some((newKvp) => newKvp.id === kvp.id)
    );

    newKvps = [
      ...newKvps.map((kvp) => {
        const kvpBeingUpdated = kvpsBeingUpdated?.find(
          (kvpBeingUpdated) => kvpBeingUpdated.id === kvp.id
        );

        return kvpBeingUpdated ? { ...kvp, ...kvpBeingUpdated } : kvp;
      }),
      ...(kvpsBeingAdded ?? []),
    ];

    return {
      ...previousDocument,
      name: updatedDocument.name ?? previousDocument.name ?? "",
      kvps: newKvps,
      ...omit(updatedDocument, "kvps"),
    };
  };

  const update = (
    updatedDocument: Partial<Document>,
    options: UpdateOptions
  ) => {
    const { doUpdateLocal = true, updateOnlyLocal = false } = options;

    if (doUpdateLocal) {
      updateMutation.mutate(updatedDocument);
    }

    if (!updateOnlyLocal) {
      return patchDocument({
        id: documentId,
        name: updatedDocument.name ?? document?.name ?? "",
        ...omit(updatedDocument, "kvps"),
      }).catch((error: Error) => {
        return error;
      });
    }
  };

  const removeKvp = (kvpId: string) => {
    if (document) {
      const updatedDocument = {
        ...document,
        kvps: document.kvps?.filter((kvp) => kvp.id !== kvpId),
      };
      queryClient.setQueryData(["document", documentId], updatedDocument);
      updateLocalStorage(documentId, updatedDocument);
    }
  };

  const fetch = async (): Promise<Document | undefined> => {
    const { data } = await refetch();
    return data;
  };

  return {
    document,
    error,
    isLoading,
    update,
    fetch,
    removeKvp,
  };
};
