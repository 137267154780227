import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useUser } from "@auth0/nextjs-auth0/client";

import axios from "axios";
import { Subject } from "rxjs";
import { io } from "socket.io-client";

import { RealtimeContext, SocketEvent } from "./RealtimeContext";

export const RealtimeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const isGettingTokenRef = useRef<boolean>(false);
  const eventsSubject = useMemo(() => new Subject<SocketEvent>(), []);

  const { user, isLoading: isLoadingAuth0 } = useUser();

  useEffect(() => {
    if (!user || isLoadingAuth0 || isGettingTokenRef.current) return;

    const getAccessToken = async () => {
      try {
        isGettingTokenRef.current = true;
        const { data } = await axios.get("/api/token");
        setAccessToken(data.accessToken);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Could not activate websocket", error);
      } finally {
        isGettingTokenRef.current = false;
      }
    };

    getAccessToken();
  }, [user, isLoadingAuth0]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const socket = io(
      process.env["NEXT_PUBLIC_WEBSOCKETS_URL"] ??
        "wss://atlas-api.sandbox.nimble.la/",
      {
        transports: ["polling", "websocket"],
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const handleConnect = () => setIsConnected(true);
    const handleDisconnect = () => setIsConnected(false);
    const handleMessage = (data: SocketEvent) => eventsSubject.next(data);

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleDisconnect);
    socket.on("message", handleMessage);

    return () => {
      socket.off("message", handleMessage);
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, [accessToken, eventsSubject]);

  const value = useMemo(
    () => ({
      eventsSubject,
      isConnected,
    }),
    [eventsSubject, isConnected]
  );

  return (
    <RealtimeContext.Provider value={value}>
      {children}
    </RealtimeContext.Provider>
  );
};
