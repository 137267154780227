import { RefObject, useEffect, useState } from "react";
export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>
): boolean {
  const [value, setValue] = useState(false);
  useEffect(() => {
    const handleMouseEnter = () => setValue(true);
    const handleMouseLeave = () => setValue(false);
    const handleMouseMove = () => {
      if (!elementRef.current?.matches(":hover")) {
        setValue(false);
      }
    };
    window.addEventListener("mousemove", handleMouseMove);
    elementRef.current?.addEventListener("mouseenter", handleMouseEnter);
    elementRef.current?.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      elementRef.current?.removeEventListener("mouseenter", handleMouseEnter);
      elementRef.current?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);
  return value;
}
