import { ReactNode, useContext, useRef, useState } from "react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Header, RowData } from "@tanstack/react-table";

import "../types.d";
import { useOnClickOutside } from "usehooks-ts";

import { cn } from "../../../../utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../atoms/popovers/Popover";
import FilterableTableContext from "./FilterableTableContext";

interface SortableHeaderProps<T> {
  headerContent: ReactNode;
  header: Header<T, RowData>;
}

export const SortableHeader = <T,>({
  headerContent,
  header,
}: SortableHeaderProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { sortValues, sort } = useContext(FilterableTableContext);

  useOnClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  const sortHandler = (option: string) => {
    sort?.(option);
    setIsOpen(false);
  };

  return (
    <div className={"flex gap-2 items-center"}>
      <span>{headerContent}</span>
      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <button
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <ChevronUpDownIcon className={"w-4 h-4"} />
          </button>
        </PopoverTrigger>
        <PopoverContent
          ref={containerRef}
          align={"end"}
          className={
            "border flex flex-col rounded-[8px] bg-popover max-w-[204px] p-0"
          }
        >
          {header.column.columnDef.meta?.sortOptions?.map((option, index) => {
            return (
              <button
                key={option.value}
                onClick={() => sortHandler(option.value)}
                className={cn(
                  "py-1.5 px-[8px] text-left text-popover-foreground text-sm transition-colors justify-between hover:bg-accent flex items-center",
                  index === 0 && "rounded-t-[8px]",
                  index ===
                    (header.column.columnDef.meta?.sortOptions?.length ?? 0) -
                      1 && "rounded-b-[8px]"
                )}
              >
                <span>{option.label}</span>
                {sortValues.includes(option.value) && (
                  <CheckIcon
                    data-testid={"check-icon"}
                    className={"w-4 h-4 mr-2"}
                  />
                )}
              </button>
            );
          })}
        </PopoverContent>
      </Popover>
      {header.column.columnDef.meta?.headerExtra}
    </div>
  );
};
