import { FC } from "react";

import { cn } from "../../../utils";
import { Skeleton } from "../skeletons";

export const EmptyKeyValuePairContainer: FC = () => (
  <div
    className={cn("flex flex-col gap-1 p-4 relative rounded-lg")}
    data-testid="empty-kvp-container"
  >
    <div className="flex items-center justify-between gap-2">
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    </div>
  </div>
);
