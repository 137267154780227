import React, { HTMLAttributes } from "react";

import { noop } from "lodash";

import { Spinner } from "../spinners/Spinner";
import { Button } from "./Button";

interface ListActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  isLoading?: boolean;
}

export const ListActionButton: React.FC<ListActionButtonProps> = ({
  children,
  onClick,
  label,
  isLoading = false,
  ...rest
}) => (
  <Button
    onClick={isLoading ? noop : onClick}
    size="xs"
    variant="icon"
    aria-label={label}
    asChild
    {...rest}
  >
    <div className="border border-border">
      {isLoading ? <Spinner size={4} /> : children}
    </div>
  </Button>
);
