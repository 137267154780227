import { ProblemType } from "@atlas-ui/types";

import API from "../api";

export const getProblemTypes = async () => {
  try {
    const response = await API.get("/admin/problem-types");
    return response.data as ProblemType[];
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting problem types");
  }
};
