import { KeyValuePair } from "./keyvaluepairs";

export enum DocumentOldStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum DocumentStatus {
  pending = "pending",
  docxToPdfInProgress = "docx_to_pdf_in_progress",
  docxToPdfFailed = "docx_to_pdf_failed",
  docxToPdfCompleted = "docx_to_pdf_completed",
  preprocessInProgress = "preprocess_in_progress",
  preprocessFailed = "preprocess_failed",
  preprocessCompleted = "preprocess_completed",
  kvpInProgress = "kvp_in_progress",
  kvpFailed = "kvp_failed",
  kvpCompleted = "kvp_completed",
  extraKvpInProgress = "extra_kvp_in_progress",
  extraKvpFailed = "extra_kvp_failed",
  extraKvpCompleted = "extra_kvp_completed",
  completed = "completed",
}

export enum FeedbackResult {
  POSITIVE = "positive",
  NEGATIVE = "negative",
}

export interface DocumentType {
  id: string;
  name: string;
  keyValuePairs?: KeyValuePair[];

  // Internal use only
  _original?: DocumentType;
  count?: number;
}

export interface DocumentTypeWithCount {
  id: string;
  name: string;
  count: number;
}

export interface KvpSection {
  id: string;
  name: string;
  order: number;
}

export interface DocumentKeyValuePair {
  id: string;
  documentId?: string;
  name: string;
  value: string;
  isFavorite: boolean;
  isDefault: boolean;
  originalValue?: string;
  dateValue?: string;
  feedback?: FeedbackResult;
  keyValuePair?: KeyValuePair;
  section?: KvpSection | string;
}

export interface DocumentTag {
  id: string;
  name: string;
  document?: Document;
}

export interface DocumentParty {
  name: string;
}

export enum RelatedDocumentType {
  Affiliated = "affiliate",
  Renewal = "renewal",
}

export interface Document {
  userId: string;
  documentTypeId: string;
  createdAt: string;
  id: string;
  name: string;
  type: string;
  ext: string | null;
  updatedAt: string;
  url: string;
  status: DocumentStatus;
  markdownJsonUrl?: string;
  kvps?: DocumentKeyValuePair[];
  parties: string[];
  tags: { name: string }[];
  isNewUpload: boolean;
  expirationDate: string;
  insights?: { id: string; name?: string }[];
  summarization?: DocumentSummarization;
  reprocessCount: number;

  // internal use only
  _isInvalid?: boolean;
  _invalidReason?: string;
  _isTemporaryFile?: boolean;

  // Used in the admin app
  _original?: Document;
}

export interface DocumentSimilarity {
  id?: string;
  name: string;
  firstDocumentId: string;
  firstDocument?: Document;
  secondDocumentId: string;
  secondDocument?: Document;
  similarityPercentage: number;
  isAffiliate: boolean;
  isRenewal: boolean;
  renewalDocumentId?: string;
}

export interface MarkdownSection {
  text: string;
  content: string[];
}

export interface Section {
  childDocumentKey: string;
  createdAt?: string;
  id?: string;
  masterDocumentKey: string;
  status?: DocumentOldStatus | null;
  updatedAt?: string;
  comparisonResultUrl?: string;
}

export enum DocumentSummarizationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
}

export interface DocumentSummarization {
  id: string;
  result?: string;
  status: string;
  document?: Document;
}

export enum DocumentTab {
  EXPIRED = "expired",
  ACTIVE = "active",
}

export enum KvpCode {
  // Insurance
  InsuranceType = 1,
  RenewalDate = 3,
  Premiums = 4,
  TotalCharges = 5,
  Deductibles = 6,
  Limits = 7,
  PaymentTerms = 8,
  Exclusions = 9,

  // NDA
  AgreementTerm = 102,
  DisclosingParties = 103,

  // Agreement
  AgreementTermAgreement = 202,
  LaunchDate = 203,
  GoverningLaw = 204,
  MarketingRightsClause = 205,
  MarketingRights = 206,
  TerminationRightsClause = 207,
  TerminationForConvenience = 208,
  TerminationForConveniencePeriod = 209,
  TerminationForMaterialBreach = 210,
  TerminationForMaterialBreachPeriod = 211,
  AutoRenewalClause = 212,
  AutoRenew = 213,
  AutoRenewalCancellationNotice = 214,
  SLAs = 215,
  BillingTerms = 216,
  PaymentTermsAgreement = 217,
  CancellationNotice = 218,
  PenaltiesFees = 219,
  ExpirationDate = 220,
  IndemnityClause = 221,
  LimitationOfLiabilityClause = 222,
  RenewalDateAgreement = 223,

  // Generic
  Type = 901,
  Parties = 902,
  EffectiveDate = 903,

  // SOW
  SOWTerm = 301,
  SOWEffectiveDate = 302,
  SOWExpirationDate = 303,
}
