/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useReducer, useRef } from "react";
import { useQueryClient } from "react-query";

import {
  createDocumentTag,
  getDocumentTags,
  removeDocumentTag,
} from "@atlas-ui/services";
import { DocumentTag } from "@atlas-ui/types";

import { isEmpty } from "lodash";
import mixpanel from "mixpanel-browser";

interface State {
  isLoading: boolean;
  tags: DocumentTag[];
  documentTags: DocumentTag[];
  error: string | null;
}

enum ActionType {
  SetLoading = "SET_LOADING",
  SetLoadingTags = "SET_LOADING_TAGS",
  SetTags = "SET_TAGS",
  SetDocumentTags = "SET_DOCUMENT_TAGS",
  SetError = "SET_ERROR",
}

const initialState = {
  isLoading: false,
  documentTags: [],
  tags: [],
  error: null,
};

const reducer = (state: State, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ActionType.SetLoading:
      return { ...state, isLoading: action.payload };
    case ActionType.SetTags:
      return { ...state, tags: action.payload };
    case ActionType.SetDocumentTags:
      return { ...state, documentTags: action.payload };
    case ActionType.SetError:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const useDocumentTags = (documentId: string) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(reducer, initialState);

  const isLoadingRef = useRef<boolean>(false);

  const fetchTags = useCallback(async (abortSignal?: AbortSignal) => {
    if (isLoadingRef.current) {
      return;
    }

    dispatch({ type: ActionType.SetLoading, payload: true });
    isLoadingRef.current = true;

    try {
      const data = await getDocumentTags({
        abortSignal,
      });

      const documentTags = await getDocumentTags({
        abortSignal,
        documentId,
      });

      dispatch({ type: ActionType.SetTags, payload: data });
      dispatch({ type: ActionType.SetDocumentTags, payload: documentTags });
    } catch (error: any) {
      dispatch({ type: ActionType.SetError, payload: error.message });
    } finally {
      dispatch({ type: ActionType.SetLoading, payload: false });
      isLoadingRef.current = false;
    }
  }, []);

  const addTag = useCallback(
    async (tag: string) => {
      const documentTagNames = state.documentTags.map(
        (tag: DocumentTag) => tag.name
      );

      if (!documentId || isEmpty(tag) || documentTagNames.includes(tag)) return;

      dispatch({ type: ActionType.SetLoading, payload: true });

      const tempId = "__TEMP_TAG__" + Math.random();
      dispatch({
        type: ActionType.SetDocumentTags,
        payload: [
          ...state.documentTags,
          {
            id: tempId,
            name: tag,
          },
        ],
      });

      try {
        const result = await createDocumentTag({
          documentId,
          name: tag,
        });
        dispatch({
          type: ActionType.SetDocumentTags,
          payload: [...state.documentTags, result].filter(
            (t) => t.id !== tempId
          ),
        });

        // Next time we fetch documents we will fetch them again
        // and invalidate the document cache
        await queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes("documents");
          },
        });
      } catch (error: any) {
        dispatch({ type: ActionType.SetError, payload: error.message });
      } finally {
        dispatch({ type: ActionType.SetLoading, payload: false });
      }
    },
    [documentId, state.documentTags]
  );

  const removeTag = useCallback(
    async (id: string) => {
      mixpanel.track("action-remove-tag");

      dispatch({ type: ActionType.SetLoading, payload: true });

      dispatch({
        type: ActionType.SetDocumentTags,
        payload: [
          ...state.documentTags.filter((tag: DocumentTag) => tag.id !== id),
        ],
      });

      try {
        await removeDocumentTag(id);
      } catch (error: any) {
        dispatch({ type: ActionType.SetError, payload: error.message });
      } finally {
        dispatch({ type: ActionType.SetLoading, payload: false });
      }
    },
    [state.documentTags]
  );

  useEffect(() => {
    if (!documentId) return;
    const abortController = new AbortController();
    fetchTags(abortController.signal);
  }, [documentId, fetchTags]);

  return {
    isLoading: state.isLoading,
    tags: state.tags,
    error: state.error,
    documentTags: state.documentTags,
    refetchTags: fetchTags,
    addTag,
    removeTag,
  };
};
