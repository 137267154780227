import React, { FC } from "react";

import { truncate } from "lodash";

import { cn } from "../../../utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltips";
import { Badge, BadgeProps } from "./Badge";

interface TruncatedBadgeProps extends BadgeProps {
  textLength?: number;
  children: string;
}

export const TruncatedBadge: FC<TruncatedBadgeProps> = ({
  children,
  ...props
}) => {
  if (children.length <= 20) return <Badge {...props}>{children}</Badge>;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge {...props} className={cn("cursor-pointer", props.className)}>
            {truncate(children, {
              length: props.textLength ?? 20,
              omission: "...",
            })}
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <p>{children}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
