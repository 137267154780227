import { ElementType, forwardRef } from "react";

import { PropsWithChildrenAndRef } from "@atlas-ui/types";

import { twMerge } from "tailwind-merge";

import { cn } from "../../../utils";

interface TitleProps {
  description?: string;
  className?: string;
  as?: string | ElementType;
  rightAddon?: React.ReactNode;
}

const mobileClasses = "text-2xl leading-4";
const desktopClasses = "md:text-2xl md:leading-6";

export const Title = forwardRef<
  HTMLHeadingElement,
  PropsWithChildrenAndRef<TitleProps>
>(({ as, description, className, children, rightAddon }, ref) => {
  const Heading = as ?? "h1";

  return (
    <Heading
      ref={ref}
      className={cn(
        "text-black font-bold align-middle capitalize",
        twMerge(
          mobileClasses,
          desktopClasses,
          rightAddon ? "flex flex-row justify-between" : "",
          className
        )
      )}
    >
      <div>
        {children}{" "}
        {!!description && (
          <span className="text-atlas-neutral-500 font-light pl-2">
            <span className="pl-4 border-l-2 border-atlas-neutral-500">
              {description}
            </span>
          </span>
        )}
      </div>
      {rightAddon && <div data-testid="right-addon">{rightAddon}</div>}
    </Heading>
  );
});

Title.displayName = "Title";
