import {
  ChangeEvent,
  cloneElement,
  createElement,
  FC,
  HTMLAttributes,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEmpty, trim } from "lodash";
import { twMerge } from "tailwind-merge";

import { cn } from "../../../utils";
import { Input } from "../../atoms";

export interface EditableProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  children?: ReactElement;
  onChange?: (value?: string) => void;
  inputClassName?: string;
  triggerClassName?: string;
  value?: string;
  disabled?: boolean;
}

export const Editable: FC<EditableProps> = ({
  children,
  onChange,
  inputClassName,
  triggerClassName,
  value,
  disabled,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    value ?? children?.props.children
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing) {
      containerRef.current?.querySelector("input")?.focus();
    }
  }, [isEditing]);

  const clonedElement = useMemo(() => {
    return cloneElement(children ?? createElement("span"), {
      onDoubleClick: () => !disabled && setIsEditing(true),
    });
  }, [children, currentValue, disabled, value]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  };

  const save = useCallback(() => {
    if (onChange && !isEmpty(currentValue)) {
      onChange(currentValue);
    }
  }, [onChange, currentValue, value]);

  const handleOnBlur = useCallback(() => {
    setIsEditing(false);
    save();
  }, [save]);

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        (e.key === "Enter" || e.key === "Escape") &&
        !isEmpty(trim(currentValue))
      ) {
        setIsEditing(false);
        if (e.key === "Enter") {
          save();
        }
      }
    },
    [currentValue, save]
  );

  return (
    <div
      {...props}
      className={cn("inline-block", isEditing && "flex-1", props.className)}
    >
      {isEditing ? (
        <Input
          className={twMerge("w-full border-primary", inputClassName)}
          containerClassName={"flex-1"}
          defaultValue={value}
          disabled={disabled}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
        />
      ) : (
        <div
          className={cn(
            "rounded-lg cursor-pointer px-4 py-1",
            "hover:bg-muted transition-colors",
            triggerClassName
          )}
          ref={containerRef}
        >
          {clonedElement}
        </div>
      )}
    </div>
  );
};
