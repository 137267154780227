import { ReactNode } from "react";

import { AnimatePresence, motion } from "framer-motion";

export interface CollapseProps {
  children: ReactNode;
  isOpen: boolean;
  enableOpacity?: boolean;
  duration?: number;
  ease?: string;
  className?: string;
  testId?: string;
}

export const Collapse = ({
  children,
  isOpen,
  enableOpacity = true,
  duration = 0.2,
  ease = "easeOut",
  className,
  testId,
}: CollapseProps) => {
  const opacityIfEnabled = isOpen ? 1 : 0;

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          data-testid={testId}
          initial={{ height: 0, opacity: enableOpacity ? 0 : 1 }}
          animate={{
            height: "auto",
            opacity: enableOpacity ? opacityIfEnabled : 1,
            overflow: "hidden",
          }}
          exit={{
            height: 0,
            opacity: enableOpacity ? 0 : 1,
            overflow: "hidden",
          }}
          transition={{ duration, ease }}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
