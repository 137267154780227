import { ChangeEventHandler, useContext, useState } from "react";

import { DocumentIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { cn } from "../../../../utils";
import {
  Badge,
  Checkbox,
  Input,
  Separator,
  TruncatedBadge,
  TruncatedText,
} from "../../../atoms";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../atoms/popovers/Popover";
import FilterableTableContext, { FilterOption } from "./FilterableTableContext";
import { Filter } from "./index";

interface TableFilterDropdownProps {
  filter: Filter;
  classNames?: {
    container?: string;
  };
}

export const TableFilterDropdown = ({
  filter,
  classNames,
}: TableFilterDropdownProps) => {
  const {
    toggleFilterOption,
    isFilterOptionSelected,
    clearFilterValues,
    filterValues,
  } = useContext(FilterableTableContext);

  const values = filterValues[filter.accessorKey] ?? [];

  const [searchValue, setSearchValue] = useState<string>("");

  const labelsMap = filter.options?.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {} as Record<string, string>);

  const renderFilterBadges = () => {
    if (values.length > 3) {
      return <Badge variant={"filter"}>+{values.length} Selected</Badge>;
    }

    return values.map((value) => (
      <TruncatedBadge key={value} variant={"filter"} textLength={20}>
        {labelsMap?.[value] ?? value}
      </TruncatedBadge>
    ));
  };

  const renderFilterOptionButton = (option: FilterOption) => (
    <button
      key={`filter-${filter.accessorKey}-option-${option.label}`}
      data-testid={`filter-${filter.accessorKey}-option-${option.label}`}
      onClick={() => toggleFilterOption(filter, option)}
      className={
        "py-1.5 px-[8px] flex justify-between items-center cursor-pointer text-left"
      }
    >
      <div className={"flex items-center gap-[8px]"}>
        <Checkbox
          checked={isFilterOptionSelected(filter, option)}
          label={<TruncatedText textLength={24}>{option.label}</TruncatedText>}
          labelClassName="line-clamp-1"
          onClick={() => toggleFilterOption(filter, option)}
        />
      </div>
      {option.count !== undefined && (
        <div>
          <span>{option.count}</span>
        </div>
      )}
    </button>
  );

  const handleClearFilters = () => {
    clearFilterValues(filter);
  };

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) =>
    setSearchValue(e.target.value);

  const renderOptions = () => {
    if (filter.options?.length === 0) {
      return (
        <span
          className={
            "text-muted-foreground text-xs font-medium text-center p-1"
          }
        >
          No options available
        </span>
      );
    }

    return (
      filter.options
        ?.filter(
          (option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            option.value.toLowerCase().includes(searchValue.toLowerCase())
        )
        ?.map(renderFilterOptionButton) ?? []
    );
  };

  return (
    <div
      key={`filter-${filter.accessorKey}`}
      className={cn(
        "h-[36px] border border-dashed text-foreground bg-background rounded-[6px] gap-2.5 flex items-center",
        values?.length > 0 && "pr-2",
        classNames?.container
      )}
    >
      <Popover>
        <PopoverTrigger disabled={filter.disabled} asChild>
          <button
            data-testid={`filter-${filter.accessorKey}-button`}
            className={"flex items-center h-full gap-2.5 p-3"}
          >
            {filter.icon ?? (
              <DocumentIcon className={"w-4 h-4 text-foreground"} />
            )}
            <span className={"text-foreground text-xs font-medium"}>
              {filter.label}
            </span>
            <ChevronDownIcon className="w-4 h-4 text-muted-foreground" />
          </button>
        </PopoverTrigger>
        <PopoverContent
          collisionPadding={30}
          align={"start"}
          className={"shadow-md p-0 flex flex-col w-[223px]"}
        >
          <div className={"px-3 pt-3 pb-2 border-b flex items-center gap-2"}>
            <MagnifyingGlassIcon
              className={"w-[14px] h-[14px] text-muted-foreground"}
            />
            <Input
              className={"border-none p-0 h-auto"}
              value={searchValue}
              onChange={handleSearch}
              placeholder={filter.placeholder ?? "Search..."}
            />
          </div>
          <div
            className={"flex flex-col p-1 gap-1 max-h-[300px] overflow-y-auto"}
          >
            {renderOptions()}
          </div>
          {values?.length > 0 && !filter.hideClearOption && (
            <button
              className={
                "border-t p-3 hover:bg-secondary-hover transition-colors"
              }
              onClick={handleClearFilters}
            >
              <span>Clear Results</span>
            </button>
          )}
        </PopoverContent>
      </Popover>

      {values?.length > 0 && (
        <>
          <Separator orientation={"vertical"} className={"h-[20px]"} />
          {renderFilterBadges()}
        </>
      )}
    </div>
  );
};
