/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import { Document, MarkdownSection } from "@atlas-ui/types";

import axios from "axios";
import { isEmpty } from "lodash";

type UseDocumentSectionsProps = {
  document?: Document;
};

type UseDocumentSectionsResult = {
  sections: { [key: string]: MarkdownSection };
  isLoading: boolean;
  error: string;
};

export const useDocumentSections = ({
  document,
}: UseDocumentSectionsProps): UseDocumentSectionsResult => {
  const [sections, setSections] = useState<{ [key: string]: MarkdownSection }>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const loadSections = async () => {
      const { markdownJsonUrl } = document ?? {};

      if (!markdownJsonUrl || isEmpty(markdownJsonUrl)) {
        setIsLoading(false);
        return;
      }

      setError("");

      try {
        const { data } = await axios.get(markdownJsonUrl);
        setSections(data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (document) {
      loadSections();
    }
  }, [document]);

  return { sections, isLoading, error };
};
