"use client";

import { forwardRef } from "react";

import { Fallback, Image, Root } from "@radix-ui/react-avatar";

import { cva } from "class-variance-authority";
import omit from "lodash/omit";
import { twMerge } from "tailwind-merge";

import { cn } from "../../../utils";

const avatarVariants = cva(
  "inline-flex items-center justify-center rounded-full overflow-hidden",
  {
    variants: {
      size: {
        sm: "h-6 w-6",
        md: "h-10 w-10",
        lg: "h-14 w-14",
      },
      variant: {
        circle: "rounded-full",
      },
    },
    defaultVariants: {
      size: "md",
      variant: "circle",
    },
  }
);

const avatarFallbackVariants = cva("", {
  variants: {
    size: {
      sm: "text-xs",
      md: "text-md",
      lg: "text-lg",
    },
  },
});

const AvatarImage = forwardRef<
  React.ElementRef<typeof Image>,
  React.ComponentPropsWithoutRef<typeof Image>
>(({ className, ...props }, ref) => (
  <Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
));

AvatarImage.displayName = Image.displayName;

const AvatarFallback = forwardRef<
  React.ElementRef<typeof Fallback>,
  React.ComponentPropsWithoutRef<typeof Fallback>
>(({ className, ...props }, ref) => (
  <Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center bg-muted",
      className
    )}
    {...props}
  />
));

AvatarFallback.displayName = Fallback.displayName;

export interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof Root> {
  src?: string;
  alt?: string;
  fallback?: React.ReactNode;
  size: "sm" | "md" | "lg";
  fallbackClassName?: string;
}

const Avatar = forwardRef<React.ElementRef<typeof Root>, AvatarProps>(
  ({ className, size, fallbackClassName, ...props }, ref) => (
    <Root
      ref={ref}
      className={cn(
        avatarVariants({ size }),
        "relative flex shrink-0 overflow-hidden",
        className
      )}
      {...omit(props, ["asChild", "children", "src"])}
    >
      <AvatarFallback
        className={twMerge(
          avatarFallbackVariants({ size }),
          "text-slate-800",
          fallbackClassName
        )}
      >
        {props.fallback}
      </AvatarFallback>
      <AvatarImage src={props.src} alt={props.alt} />
    </Root>
  )
);

Avatar.displayName = Root.displayName;

export { Avatar, AvatarImage, AvatarFallback };
