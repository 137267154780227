import { FC } from "react";

import { DocumentTag } from "@atlas-ui/types";
import { XCircleIcon } from "@heroicons/react/24/solid";

import { TooltipProvider } from "../../atoms";
import { Badge } from "../../atoms/badges/Badge";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "../../atoms/tooltips/Tooltip";

interface DocumentTagBadgeProps {
  tag: DocumentTag;
  onRemove?: () => void;
}

export const DocumentTagBadge: FC<DocumentTagBadgeProps> = ({
  tag,
  onRemove,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge
            actionable
            onAction={onRemove}
            actionIcon={<XCircleIcon className="h-4 w-4 text-foreground" />}
            variant="inactive"
            className="max-w-[120px] text-muted-foreground font-medium"
          >
            {tag.name}
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipArrow style={{ fill: "white" }} />
          <span className="text-xs">{tag.name}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
