import { ChangeEventHandler, FC, HTMLAttributes } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

import { Input } from "../../atoms";

interface SectionFiltersProps extends HTMLAttributes<HTMLDivElement> {
  sectionsCount?: number;
  onSortingChange?: (sortType: string) => void;
  onSearchValueChange?: (value: string) => void;
  searchValue?: string;
}

export const SectionFilters: FC<SectionFiltersProps> = ({
  sectionsCount,
  onSortingChange,
  onSearchValueChange,
  searchValue,
  ...props
}) => {
  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!onSearchValueChange) return;
    onSearchValueChange(event.target.value);
  };

  return (
    <div
      data-testid="section-filters"
      className="flex items-center gap-4 py-2 px-4"
      {...props}
    >
      <div className="text-sm text-slate-500 flex-1 flex gap-2 items-center">
        <Input
          startIcon={
            <MagnifyingGlassIcon className="h-4 w-4 text-foreground" />
          }
          placeholder="Search"
          onChange={handleSearchChange}
          className="max-w-[280px] w-full"
          value={searchValue}
          data-testid="search-input"
        />
        <span
          data-testid="section-count"
          className="text-sm text-slate-500 flex-1"
        >
          {sectionsCount ?? 0} {sectionsCount === 1 ? "Section" : "Sections"}{" "}
          found
        </span>
      </div>
    </div>
  );
};
