import { RefObject, useEffect, useState } from "react";

export interface UseSaveScrollProps {
  ref: RefObject<HTMLElement>;
  storageKey: string;
}

export const useSaveScroll = ({ ref, storageKey }: UseSaveScrollProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(
    isNaN(Number(localStorage.getItem(storageKey)) || 0)
      ? 0
      : Number(localStorage.getItem(storageKey))
  );

  useEffect(() => {
    if (!ref.current?.scrollTo) {
      return;
    }

    ref.current?.scrollTo(0, scrollPosition);

    ref.current?.addEventListener("scroll", () => {
      setScrollPosition(ref.current?.scrollTop ?? 0);
      localStorage.setItem(storageKey, String(ref.current?.scrollTop ?? 0));
    });
  }, [ref, scrollPosition, storageKey]);

  return [scrollPosition, setScrollPosition];
};
