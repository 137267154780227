import { forwardRef } from "react";

import { cn } from "../../../utils";
import { Title } from "./Title";

export const AlertTitle = forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <Title
    as="h5"
    className={cn(
      "mb-0 text-base md:text-base font-medium leading-none tracking-tight",
      className
    )}
    {...props}
    ref={ref}
  />
));

AlertTitle.displayName = "AlertTitle";
