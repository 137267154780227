import { useQuery } from "react-query";

import { getOrganizationLimits } from "@atlas-ui/services";

import { useEvent } from "../hooks";
import { Entity, EventData, EventType } from "./RealtimeContext";
import { useDocsnapUser } from "./useDocsnapUser";

// Responsible for fetching the limits from the organization of the logged-in user
export const useDocsnapLimits = () => {
  const { data: user } = useDocsnapUser();

  // We assume that the user only has a single organization.
  const organizationId = user?.organizations?.[0]?.id;

  const query = useQuery({
    queryKey: ["organization-limits", organizationId],
    queryFn: () => getOrganizationLimits(organizationId),
    enabled: !!organizationId,
    onError(err) {
      console.error(err);
    }
  });

  const handleWebsocketEvent = async (type: EventType, data: EventData) => {
    if (type === EventType.UPDATE && data.id === organizationId) {
      await query.refetch();
    }
  };

  useEvent({
    entity: Entity.USAGE,
    events: [EventType.UPDATE],
    handler: handleWebsocketEvent
  })

  return query;
};
