import * as React from "react";
import { forwardRef } from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../../utils";
import { Button } from "../buttons";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 group relative",
  {
    variants: {
      variant: {
        primary: "border-transparent bg-primary text-primary-foreground",
        inactive: "border-transparent bg-secondary text-secondary-foreground",
        actionable: "border-transparent bg-secondary-foreground text-secondary",
        filter:
          "border-transparent bg-muted text-muted-foreground rounded-[4px]",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  actionable?: boolean;
  onAction?: () => void;
  actionIcon?: React.ReactNode;
}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      className,
      variant,
      children,
      actionable,
      onAction,
      actionIcon,
      ...props
    }: BadgeProps,
    ref
  ) => (
    <div
      className={cn(badgeVariants({ variant }), className)}
      data-testid="badge"
      {...props}
      ref={ref}
    >
      <span className="line-clamp-1">{children}</span>
      {actionable && (
        <div className="hidden group-hover:flex w-auto h-full top-0 left-[5px] justify-end relative items-center">
          <Button
            variant="ghost"
            className="px-0 py-0 hover:bg-transparent"
            onClick={onAction}
            data-testid="action-button"
          >
            {actionIcon}
          </Button>
        </div>
      )}
    </div>
  )
);

export { Badge, badgeVariants };
