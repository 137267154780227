import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { Title } from "@radix-ui/react-alert-dialog";

import { cn } from "../../../utils";

export const AlertDialogTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title
    ref={ref}
    className={cn("text-lg font-semibold", className)}
    {...props}
  />
));

AlertDialogTitle.displayName = Title.displayName;
