import { PropsWithChildren, ReactNode } from "react";

interface TopBarProps {
  rightSlot?: ReactNode;
  bottomSlot?: ReactNode;
}

export const TopBar = ({
  children,
  rightSlot,
  bottomSlot,
}: PropsWithChildren<TopBarProps>) => {
  return (
    <div className="flex flex-col gap-2">
      <div className={"flex justify-between flex-wrap"}>
        <div className="flex items-center gap-4 flex-wrap gap-y-2">{children}</div>
        <div>{rightSlot}</div>
      </div>
      {bottomSlot}
    </div>
  );
};
