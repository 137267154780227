import React, { HTMLAttributes, ReactNode } from "react";

import { ChevronRightIcon } from "@heroicons/react/24/solid";

import Link from "next/link";

import { cn } from "../../../utils";

type BreadcrumbWithLabel = {
  label: string;
  link: string;
  icon?: ReactNode;
  iconSide?: "left" | "right";
  render?: never;
  className?: string;
};

type BreadcrumbWithRender = {
  label?: never;
  link: string;
  className?: string;
  render: () => ReactNode;
};

export type BreadcrumbUnion = BreadcrumbWithLabel | BreadcrumbWithRender;

interface BreadcrumbsProps extends HTMLAttributes<HTMLDivElement> {
  breadcrumbs: BreadcrumbUnion[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  ...props
}) => {
  const renderBreadcrumb = (breadcrumb: BreadcrumbUnion) => {
    if (breadcrumb.label) {
      return (
        <Link
          className={cn(
            "flex items-center text-xs text-primary",
            breadcrumb.className
          )}
          href={breadcrumb.link}
        >
          {breadcrumb.icon && breadcrumb.iconSide === "left" && breadcrumb.icon}
          <span className={cn("text-primary")}>{breadcrumb.label}</span>
          {breadcrumb.icon && breadcrumb.iconSide !== "left" && breadcrumb.icon}
          {!breadcrumb.icon && (
            <ChevronRightIcon
              data-testid={`chevron-icon-${breadcrumb.label}`}
              className="h-3 w-3 text-muted-foreground"
            />
          )}
        </Link>
      );
    }

    if (breadcrumb.render) {
      return breadcrumb.render();
    }

    return null;
  };

  return (
    <nav aria-label="breadcrumb" {...props}>
      <ul className="breadcrumb flex gap-[4px]">
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <li key={`breadcrumb-${breadcrumb.label}`}>
              {renderBreadcrumb(breadcrumb)}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
