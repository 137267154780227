import { FC, useEffect, useState } from "react";

import { truncate } from "lodash";
import { ChevronDown } from "lucide-react";

import { cn } from "../../../utils";
import {
  Checkbox,
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
} from "../../atoms";

interface DropdownOption {
  id: string;
  name: string;
}

interface DropdownProps {
  label: string;
  options: DropdownOption[];
  placeholder?: string;
  values?: string[];
  onChange?: (values: string[]) => void;
  className?: string;
  labelClassName?: string;
}

export const MultipleDropdown: FC<DropdownProps> = ({
  values,
  options,
  placeholder,
  label,
  onChange,
  className,
  labelClassName,
}) => {
  const [internalValues, setInternalValues] = useState<string[]>(values ?? []);

  useEffect(() => {
    setInternalValues(values ?? []);
  }, [options, values]);

  const toggleOption = (option: DropdownOption) => {
    return () => {
      const newValues = internalValues.includes(option.id)
        ? internalValues.filter((v) => v !== option.id)
        : [...internalValues, option.id];
      setInternalValues(newValues);
      onChange?.(newValues);
    };
  };

  const toggleAllOptions = () => {
    const newValues =
      internalValues.length === options.length
        ? []
        : options.map((option) => option.id);
    setInternalValues(newValues);
    onChange?.(newValues);
  };

  const selectedOptions = internalValues
    .map((value) => {
      const option = options.find((option) => option.id === value);
      return option?.name;
    })
    .join(", ");

  const truncatedValues = truncate(selectedOptions, {
    length: 40,
  });

  return (
    <Select>
      <SelectGroup className={cn("w-full flex flex-col gap-1", className)}>
        <SelectLabel
          className={cn("text-sm font-semibold p-0", labelClassName)}
        >
          {label}
        </SelectLabel>
        <SelectTrigger
          icon={<ChevronDown className={"w-4 h-4 text-muted-foreground"} />}
          className={"h-[32px]"}
          data-testid={"dropdown-trigger"}
        >
          {internalValues.length === 0 ? (
            placeholder ?? "Select an option"
          ) : (
            <div className={"text-start"}>{truncatedValues}</div>
          )}
        </SelectTrigger>
        <SelectContent className={"overflow-y-auto max-h-[25rem]"}>
          <div className={"py-1.5 px-2"} data-testid={"dropdown"}>
            <button
              className={"text-primary cursor-pointer text-sm"}
              onClick={toggleAllOptions}
            >
              {internalValues.length === options.length
                ? "Unselect all"
                : "Select all"}
            </button>
          </div>
          <div className={"flex flex-col"}>
            {options.map((option) => (
              <button
                className={
                  "flex flex-row gap-2 py-1.5 px-2 items-center cursor-pointer"
                }
                onClick={toggleOption(option)}
                key={option.id}
              >
                <Checkbox checked={internalValues.includes(option.id)} />
                <span>{option.name}</span>
              </button>
            ))}
          </div>
        </SelectContent>
      </SelectGroup>
    </Select>
  );
};
