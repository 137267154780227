import axios, { AxiosError } from "axios";

const API = axios.create({
  baseURL:
    process.env["NEXT_PUBLIC_PROXY_BASE_URL"] ?? "https://atlas.web.nimble.la/api/proxy",
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(async (config) => {
  const isBackend = typeof window === "undefined";
  const isProxy = isBackend && config.headers["X-Proxy"] === "true";

  if (isProxy) {
    config.baseURL = process.env["BACKEND_URL"];
  } else if (isBackend) {
    const { cookies } = await import("next/headers");
    const cookieStore = cookies();
    const accessToken = cookieStore.get("appSession")?.value;

    config.headers["Authorization"] = `Bearer ${accessToken ?? ""}`;
  }

  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (typeof window !== "undefined" && error.response?.status === 401) {
      window.location.href = "/api/auth/login";
    }

    return Promise.reject(error);
  }
);

export default API;
