"use client";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from "react";

import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";

import { noop } from "lodash";

import "jsoneditor/dist/jsoneditor.css";
import "./JsonEditor.css";

interface JsonEditorProps {
  value: object;
  onChangeText: (value: string) => void;
}

export const JsonEditor: FC<JsonEditorProps> = ({ value, onChangeText }: {
  value: object;
  onChangeText: (value: string) => void;
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const editorRef = useRef<any>(null);

  useEffect(() => {
    import("jsoneditor").then(({ default: JSONEditor }) => {
      const container = document.getElementById("jsoneditor");
      if (container) {
        const editor = new JSONEditor(container, {
          mode: "code",
          onChangeText,
          onError: noop,
        });
        editor.set(value);
        editorRef.current = editor;
      }
    });

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (editorRef.current?._debouncedValidate) {
      editorRef.current.onChangeText = onChangeText;
    }
  }, [onChangeText]);

  const copyToClipboard = async () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    await navigator.clipboard.writeText(JSON.stringify(value, null, 2));
  };

  return (
    <div className={"relative w-full h-full"}>
      <button
        className={"absolute top-0 right-0 z-10 bg-primary p-1 rounded-md"}
        onClick={copyToClipboard}
        disabled={copied}
      >
        {copied ? (
          <CheckIcon className={"w-[18px] h-[18px] text-white"} />
        ) : (
          <DocumentDuplicateIcon
            className={"w-[18px] h-[18px] bg-primary text-white"}
          />
        )}
      </button>
      <div id="jsoneditor"></div>
    </div>
  );
};
