import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { Action } from "@radix-ui/react-alert-dialog";

import { cn } from "../../../utils";
import { ButtonSize, ButtonVariant, buttonVariants } from "./Button";

interface AlertDialogActionProps
  extends ComponentPropsWithoutRef<typeof Action> {
  variant?: ButtonVariant;
  size?: ButtonSize;
}

export const AlertDialogAction = forwardRef<
  ElementRef<typeof Action>,
  AlertDialogActionProps
>(({ className, variant, size, ...props }, ref) => (
  <Action
    ref={ref}
    className={cn(
      buttonVariants({
        variant,
        size,
      }),
      className
    )}
    {...props}
  />
));

AlertDialogAction.displayName = Action.displayName;
