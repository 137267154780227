import React, { cloneElement, ReactElement, ReactNode } from "react";

export const TableSortLabel = ({
  label,
  icon,
}: {
  label: string;
  icon: ReactNode;
}) => (
  <div className={"gap-[8px] flex items-center"}>
    {icon &&
      cloneElement(icon as ReactElement, {
        className: "h-4 w-4 text-foreground",
      })}
    <span>{label}</span>
  </div>
);
