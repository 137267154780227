import { useCallback, useMemo, useState } from "react";

import { ErrorDialog } from "../organisms/dialogs/ErrorDialog";
import { ErrorContext, ErrorContextType, ErrorType } from "./ErrorContext";

interface ErrorProviderProps {
  children: React.ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<ErrorType>({
    title: "Something went wrong",
    message: "Please try again later",
  });

  const setErrorAndOpenModal = useCallback(
    (error: ErrorType) => {
      setError(error);
      setOpen(true);
    },
    [setError]
  );

  const errorContext = useMemo<ErrorContextType>(
    () => ({
      open,
      onOpenChange: setOpen,
      error,
      setError: setErrorAndOpenModal,
    }),
    [open, error, setErrorAndOpenModal]
  );

  return (
    <ErrorContext.Provider value={errorContext}>
      {children}
      <ErrorDialog />
    </ErrorContext.Provider>
  );
};
