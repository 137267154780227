import { createElement, FC, PropsWithChildren, useState } from "react";

import { TagIcon } from "@heroicons/react/24/solid";

import { Button, ButtonProps } from "../../atoms";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../atoms/popovers/Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./Command";

interface ComboboxProps {
  className?: string;
  value?: string;
  options?: ComboboxOption[];
  placeholder?: string;
  emptyMessage?: string;
  onChange?: (value: string) => void;
  slots?: {
    Trigger: FC<ComoboboxTriggerProps>;
  };
}

export interface ComboboxOption {
  label: string;
  value: string;
}

export interface ComoboboxTriggerProps extends ButtonProps {
  value?: string;
  options?: ComboboxOption[];
  onClick: () => void;
}

export const ComboboxTrigger: FC<PropsWithChildren<ComoboboxTriggerProps>> = ({
  children,
  value,
  options = [],
  onClick,
  ...props
}) => {
  return (
    <Button
      variant="secondary"
      aria-expanded={true}
      className="w-[200px] justify-between"
      onClick={onClick}
      {...props}
    >
      {value
        ? options.find((option) => option.value === value)?.label
        : "Select"}
    </Button>
  );
};

export const Combobox: FC<ComboboxProps> = ({
  className,
  onChange,
  options,
  value,
  placeholder,
  emptyMessage,
  slots,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        {slots?.Trigger ? (
          createElement(slots.Trigger, {
            value,
            options,
            onClick: () => setOpen(!open),
          })
        ) : (
          <ComboboxTrigger
            value={value}
            options={options}
            onClick={() => setOpen(!open)}
          ></ComboboxTrigger>
        )}
      </PopoverTrigger>
      <PopoverContent className={className}>
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandEmpty>{emptyMessage}</CommandEmpty>
          <CommandGroup>
            {options?.map((option) => (
              <CommandItem
                className="flex gap-2"
                key={option.value}
                value={option.value}
                onSelect={() => {
                  setOpen(false);
                  onChange?.(option.value);
                }}
              >
                <TagIcon className="h-4 w-4 fill-muted-foreground" />
                <span>{option.label}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
