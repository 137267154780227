import { twMerge } from "tailwind-merge";

import { InputProps } from "../../atoms";
import { Input } from "../../atoms/inputs/Input";

interface FormFieldProps extends Omit<InputProps, "id"> {
  id: string;
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
  inputContainerClassName?: string;
  error?: string;
}

export const FormField = ({
  label,
  labelClassName = "",
  containerClassName = "",
  inputContainerClassName = "",
  ...props
}: FormFieldProps) => (
  <div className={twMerge("flex flex-col gap-1", containerClassName)}>
    {label && (
      <label
        htmlFor={props.id}
        className={twMerge("text-sm text-muted-foreground", labelClassName)}
      >
        {label}
      </label>
    )}
    <Input containerClassName={inputContainerClassName} {...props} />
  </div>
);
