import { ComponentPropsWithoutRef, forwardRef } from "react";

import { cn } from "../../../utils";

interface CheckboxGroupProps {
  className?: string;
  testId?: string;
}

export const CheckboxGroup = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div"> & CheckboxGroupProps
>(({ className, testId = "checkbox-group", ...props }, ref) => (
  <div
    className={cn("grid gap-2", className)}
    {...props}
    ref={ref}
    data-testid={testId}
  />
));

CheckboxGroup.displayName = "CheckboxGroup";
