/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

export const isEqualIgnoreCase = (a: string, b: string) => {
  return a.toLowerCase() === b.toLowerCase();
};

export const parseTermString = (input: string) => {
  const normalizedInput = input?.trim().toLowerCase() ?? "";

  const unitsMapping: { [key: string]: string } = {
    day: "Days",
    month: "Months",
    year: "Years",
  };

  const regex = /(\d+)\s*(day|month|year)s?/i;
  const match = regex.exec(normalizedInput);

  if (match) {
    const count = parseInt(match[1], 10);
    const unit = match[2].toLowerCase();
    return `${count} ${unitsMapping[unit]}`;
  } else {
    return "";
  }
};

export const safeParse = (data: any, defaultValue: any) => {
  try {
    const parsed = JSON.parse(data);
    if (!parsed) return defaultValue;
    return parsed;
  } catch (error) {
    return defaultValue;
  }
};

export const toMB = (bytes?: number) => {
  if (bytes === 0 || bytes === undefined) return "0";
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(bytes / (1024 * 1024));
};

export const downloadFile = async (
  downloadUrl: string,
  fileName: string,
  fileType = "application/pdf"
) => {
  const { data } = await axios.get(downloadUrl, {
    responseType: "blob",
  });

  const blobWithName = new Blob([data], {
    type: `${fileType};filename=${fileName}`,
  });

  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blobWithName);
  downloadLink.download = fileName;
  downloadLink.click();
};
