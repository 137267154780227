import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { getOrganizationMembers, getOrganizations } from "@atlas-ui/services";
import { User } from "@atlas-ui/types";

import { first, isEmpty } from "lodash";
import mixpanel from "mixpanel-browser";

import { AuthenticationContext } from "../context";

interface UseOrganizationMembersProps {
  defaultMembers?: User[];
  enabled?: boolean;
}

export const useOrganizationMembers = ({
  defaultMembers,
  enabled = true,
}: UseOrganizationMembersProps) => {
  const [loadedMembers, setLoadedMembers] = useState<User[]>(
    defaultMembers ?? []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const isLoadingOrgRef = useRef<boolean>(false);

  const { user } = useContext(AuthenticationContext);

  const getMembers = useCallback(async () => {
    if (!enabled || !user || isLoadingOrgRef.current) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      isLoadingOrgRef.current = true;

      const organizations = await getOrganizations();

      if (!organizations || isEmpty(organizations)) {
        return;
      }

      const defaultOrganization = first(organizations);

      if (!defaultOrganization) {
        return;
      }

      const members = await getOrganizationMembers(defaultOrganization.id);

      setLoadedMembers(members);
      setIsOwner(defaultOrganization.userId === user?.id);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
      isLoadingOrgRef.current = false;
    }
  }, [enabled, user]);

  useEffect(() => {
    getMembers();
  }, [getMembers, enabled]);

  const onMemberRemoved = (member: User) => {
    setLoadedMembers((members) =>
      members.filter((mem) => mem.id !== member.id)
    );

    mixpanel.track("action-success-remove-user");
  };

  return {
    error,
    isLoading: loading,
    isOwner,
    reload: getMembers,
    loadedMembers,
    onMemberRemoved,
  };
};
