import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  getInsightById,
  updateInsight as patchInsight,
} from "@atlas-ui/services";
import { Insight } from "@atlas-ui/types";

import { merge } from "lodash";

export interface UseInsightProps {
  insightId: string;
  autoFetch?: boolean;
}

export interface UpdateInsightOptions {
  doUpdateLocal?: boolean;
  updateOnlyLocal?: boolean;
}

export const useInsight = ({
  insightId,
  autoFetch = true,
}: UseInsightProps) => {
  const queryClient = useQueryClient();

  const {
    data: insight,
    isLoading,
    error,
    refetch,
  } = useQuery<Insight, Error>(
    ["insight", insightId],
    () => getInsightById(insightId),
    {
      enabled: autoFetch && !!insightId,
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error("Error fetching insight:", error.message);
      },
    }
  );

  const mutation = useMutation(
    ({ insightId, data }: { insightId: string; data: Partial<Insight> }) =>
      patchInsight(insightId, data),
    {
      onMutate: async ({ insightId, data }) => {
        await queryClient.cancelQueries(["insight", insightId]);

        const previousInsight = queryClient.getQueryData<Insight>([
          "insight",
          insightId,
        ]);

        queryClient.setQueryData<Insight>(["insight", insightId], (old) =>
          merge({}, old, data)
        );

        return { previousInsight };
      },
      onError: (error, { insightId }, context) => {
        if (context?.previousInsight) {
          queryClient.setQueryData<Insight>(
            ["insight", insightId],
            context.previousInsight
          );
        }
        // eslint-disable-next-line no-console
        console.error("Error updating insight:", (error as Error).message);
      },
      onSettled: async (data, error, { insightId }) => {
        await queryClient.invalidateQueries(["insight", insightId]);
      },
    }
  );

  const updateInsight = async (
    insightId: string,
    data: Partial<Insight>,
    options: UpdateInsightOptions = {
      doUpdateLocal: true,
      updateOnlyLocal: false,
    }
  ) => {
    const { doUpdateLocal, updateOnlyLocal } = options;

    if (!updateOnlyLocal) {
      await mutation.mutateAsync({ insightId, data });
    }

    if (doUpdateLocal || updateOnlyLocal) {
      queryClient.setQueryData<Insight>(["insight", insightId], (old) =>
        merge({}, old, data)
      );
    }
  };

  useEffect(() => {
    if (!insightId) return;

    if (autoFetch) {
      refetch();
    }
  }, [insightId, autoFetch, refetch]);

  return {
    fetchInsight: refetch,
    updateInsight,
    isLoading,
    insight,
    error: error?.message,
  };
};
