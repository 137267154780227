import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactElement,
} from "react";

import { Indicator, Item } from "@radix-ui/react-radio-group";

import { Circle } from "lucide-react";

import { cn } from "../../../utils";
import { Label } from "../texts/Label";

interface RadioProps<ID extends string = string>
  extends Omit<ComponentPropsWithoutRef<typeof Item>, "id"> {
  id?: ID;
  label?: ID extends undefined ? never : string | ReactElement | undefined;
}

export const Radio = forwardRef<ElementRef<typeof Item>, RadioProps>(
  ({ className, children, label, ...props }, ref) => (
    <div className="flex items-center space-x-2">
      <Item
        ref={ref}
        className={cn(
          "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        data-testid="radio-item"
        {...props}
      >
        <Indicator className="flex items-center justify-center">
          <Circle className="h-2.5 w-2.5 fill-current text-current" />
        </Indicator>
      </Item>
      {label && (
        <Label htmlFor={props.id} className="text-sm">
          {label}
        </Label>
      )}
    </div>
  )
);

Radio.displayName = Item.displayName;
