import { useQuery } from "react-query";

import { getUser } from "@atlas-ui/services";
import { useUser } from "@auth0/nextjs-auth0/client";

// Responsible for fetching a user from the API
export const useDocsnapUser = () => {
  const { user: auth0User } = useUser();

  return useQuery({
    retry: false,
    queryKey: ["user"],
    queryFn: getUser,
    enabled: !!auth0User,
    onError(err) {
      console.error(err);
    }
  });
};
