import { useEffect, useMemo, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";

const validateBlobUrl = async (
  blobUrl: string,
  fetchFn = window.fetch,
  signal?: AbortSignal
): Promise<boolean> => {
  try {
    const response = await fetchFn(blobUrl, { signal });
    const contentType = response.headers.get("content-type");
    const responseText = await response.text();

    return (
      response.ok &&
      contentType === "application/pdf" &&
      !responseText.includes("Error")
    );
  } catch (error) {
    console.error(
      error instanceof Error && error.name === "AbortError"
        ? "Request aborted"
        : error
    );
    return false;
  }
};

interface GetDownloadedFileBlobUrl {
  url: string;
  fetchFn?: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;
  createObjectURL?: (obj: Blob | MediaSource) => string;
  signal?: AbortSignal;
}

const getDownloadedFileBlobUrl = async ({
  url,
  fetchFn = fetch,
  createObjectURL = URL.createObjectURL,
  signal,
}: GetDownloadedFileBlobUrl): Promise<string> => {
  const response = await fetchFn(url, {
    signal,
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/pdf",
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }

  const blob = new Blob([await response.blob()], { type: "application/pdf" });

  return createObjectURL(blob);
};

export const useDownloadDocumentFile = (
  url: string
): DownloadFileHookResult => {
  const [validatedFileUrl, setValidatedFileUrl] = useState<string | undefined>(
    undefined
  );

  const queryKey = useMemo(() => ["downloadFile", url], [url]);

  const {
    isLoading,
    isError,
    data: fileUrl,
    error,
  }: UseQueryResult<string, Error> = useQuery<string, Error>({
    queryKey,
    queryFn: () => getDownloadedFileBlobUrl({ url: url }),
    onError: console.error,
    enabled: !!url,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    keepPreviousData: false,
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });

  useEffect(() => {
    const controller = new AbortController();
    const validateUrl = async () => {
      if (fileUrl && !validatedFileUrl) {
        const isValid = await validateBlobUrl(
          fileUrl,
          window.fetch,
          controller.signal
        );
        if (!isValid) {
          const newFileUrl = await getDownloadedFileBlobUrl({
            url: url,
            fetchFn: fetch,
            createObjectURL: URL.createObjectURL,
            signal: controller.signal,
          });
          setValidatedFileUrl(newFileUrl);
        } else if (fileUrl !== validatedFileUrl) {
          setValidatedFileUrl(fileUrl);
        }
      }
    };

    validateUrl().catch(console.error);
    return () => controller.abort();
  }, [fileUrl, url, validatedFileUrl]);

  return {
    isLoading,
    fileUrl: isError ? undefined : validatedFileUrl,
    error: error?.message ?? null,
  };
};

interface DownloadFileHookResult {
  isLoading: boolean;
  fileUrl?: string;
  error: string | null;
}
