import { createContext, ReactNode } from "react";

export const defaultTitle = "Something went wrong";
export const defaultMessage = "Please try again later";

export interface ErrorType {
  title: string;
  message: ReactNode;
  acceptLabel?: string;
  acceptVariant?: string;
}

export interface ErrorContextType {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  error: ErrorType;
  setError: (error: ErrorType) => void;
}

export const ErrorContext = createContext<ErrorContextType>({
  open: false,
  onOpenChange: () => {
    // do nothing
  },
  error: {
    title: "",
    message: "",
    acceptLabel: "Accept",
    acceptVariant: "primary",
  },
  setError: () => {
    // do nothing
  },
});
