import { createContext } from "react";

import { LimitType, OrganizationLimits, User } from "@atlas-ui/types";

import { ShownLimits } from "./AuthenticationProvider";

export interface AuthenticationContextType {
  user: User | null;
  limits: OrganizationLimits | null;
  isLoading: boolean;
  updateUser: (user: Partial<User>) => void;
  getLimit: (type: LimitType) => ShownLimits;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  user: null,
  limits: null,
  isLoading: false,
  getLimit: () => {
    return {
      limit: 0,
      used: 0,
    };
  },
  updateUser: () => {
    // no-op
  },
});
